<template>
    <v-container fill-height>
        <v-layout justify-center align-center>
            <v-flex xs12 sm6 md4 lg3 xl3>
                <SanackBar
                    :snackActivated="snackActivated"
                    :success="snackSuccess"
                    :snackMessage="snackMessage"
                    @closeSnackBar="closeSnack()" />

                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>{{ titles.toolbar }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-progress-circular
                            indeterminate
                            color="white"
                            width="2"
                            v-show="loadingActivated">
                        </v-progress-circular>
                    </v-toolbar>

                    <v-card-text v-if="submitedForm">
                        <v-container class="box-submited">
                            <h1 class="info-title">Senha alterada!</h1>
                            <p class="info-msg">Sua senha foi alterada corretamente!</p>
                        </v-container>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-container class="field">
                                            <CloseField
                                                :name="'currentPassword'"
                                                :activated="
                                                    getStatusFieldComputed(
                                                        'currentPassword'
                                                    )
                                                "
                                                @closeSpeficField="closeField($event)" />

                                            <v-text-field
                                                :label="getLabel('currentPassword')"
                                                :disabled="loadingActivated"
                                                name="currentPassword"
                                                ref="currentPassword"
                                                :background-color="
                                                    fields.currentPassword.bgColor
                                                "
                                                :append-icon="
                                                    getIconFieldComputed(
                                                        'currentPassword'
                                                    )
                                                "
                                                :type="
                                                    getFieldTypeCurrentPasswordComputed
                                                "
                                                filled
                                                rounded
                                                dense
                                                v-model.trim="
                                                    $v.user.currentPassword.$model
                                                "
                                                :error-messages="
                                                    currentPasswordErrorsComputed
                                                "
                                                :success="currentPasswordSuccessComputed"
                                                @select="activeField('currentPassword')"
                                                @focus="activeField('currentPassword')"
                                                @click:append="
                                                    isTypeFieldCurrentPassword =
                                                        !isTypeFieldCurrentPassword
                                                ">
                                            </v-text-field>
                                        </v-container>

                                        <v-container class="field">
                                            <CloseField
                                                :name="'password'"
                                                :activated="
                                                    getStatusFieldComputed('password')
                                                "
                                                @closeSpeficField="closeField($event)" />

                                            <v-text-field
                                                :label="getLabel('password')"
                                                :disabled="loadingActivated"
                                                name="password"
                                                ref="password"
                                                :background-color="
                                                    fields.password.bgColor
                                                "
                                                :append-icon="
                                                    getIconFieldComputed('password')
                                                "
                                                :type="getFieldTypePasswordComputed"
                                                filled
                                                rounded
                                                dense
                                                v-model.trim="$v.user.password.$model"
                                                :error-messages="passwordErrorsComputed"
                                                :success="passwordSuccessComputed"
                                                @select="activeField('password')"
                                                @focus="activeField('password')"
                                                @click:append="
                                                    isTypeFieldPassword =
                                                        !isTypeFieldPassword
                                                ">
                                            </v-text-field>
                                        </v-container>

                                        <v-container class="field">
                                            <CloseField
                                                :name="'confirmPassword'"
                                                :disabled="loadingActivated"
                                                :activated="
                                                    getStatusFieldComputed(
                                                        'confirmPassword'
                                                    )
                                                "
                                                @closeSpeficField="closeField($event)" />

                                            <v-text-field
                                                :label="getLabel('confirmPassword')"
                                                type="password"
                                                name="confirmPassword"
                                                ref="confirmPassword"
                                                :background-color="
                                                    fields.confirmPassword.bgColor
                                                "
                                                filled
                                                rounded
                                                dense
                                                v-model.trim="
                                                    $v.user.confirmPassword.$model
                                                "
                                                :error-messages="
                                                    confirmPasswordErrorsComputed
                                                "
                                                :success="confirmPasswordSuccessComputed"
                                                @select="activeField('confirmPassword')"
                                                @focus="activeField('confirmPassword')">
                                            </v-text-field>
                                        </v-container>

                                        <SubmitFormButton
                                            :title="titles.submitButton"
                                            :disabled="loadingActivated"
                                            @submitForm="submitForm()" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { required, minLength } from 'vuelidate/lib/validators';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import CloseField from '../components/geral/CloseIconField.vue';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import geralConfig from '../configs/geral.config';
    import changeProfileConfig from '../configs/change.password.config';
    import userMock from '../mocks/change.password.mock';
    import SubmitFormButton from '../components/geral/SubmitFormButton.vue';

    export default {
        name: 'ChangePassword',
        components: { SanackBar, CloseField, SubmitFormButton },
        data() {
            return {
                isAmbientLocalTests: true,
                submitedForm: false,
                loadingActivated: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                fieldNameActivated: null,
                fieldSelected: null,
                isTypeFieldCurrentPassword: true,
                isTypeFieldPassword: true,
                user: {
                    currentPassword: null,
                    password: null,
                    confirmPassword: null
                },
                titles: {
                    toolbar: changeProfileConfig.titles.SECTOR,
                    goToSignUp: changeProfileConfig.geral.buttons.SIGNUP,
                    goToForgotPassword: changeProfileConfig.geral.buttons.FORGOT_PASSWORD,
                    submitButton: changeProfileConfig.buttons.SUBMIT
                },
                configs: {
                    fields: {
                        bgColor: {
                            error: '#ffffcc'
                        }
                    }
                },
                fields: {
                    currentPassword: {
                        btClose: false,
                        bgColor: null
                    },
                    password: {
                        btClose: false,
                        bgColor: null
                    },
                    confirmPassword: {
                        btClose: false,
                        bgColor: null
                    }
                }
            };
        },
        mounted() {
            this.populateGeral();

            this.activeField('currentPassword');

            this.changeColorField('currentPassword');

            this.focusField('currentPassword');

            this.checkStateFields();
        },
        watch: {
            user: {
                handler(e) {
                    this.monitoreUserFieldsComputed(e);
                },
                deep: true
            }
        },
        computed: {
            monitoreUserFieldsComputed() {
                return (e) => {
                    for (const key of Object.keys(this.fields)) {
                        if (e[key] && e[key].length) {
                            this.fields[key].btClose = true;
                        } else {
                            this.fields[key].btClose = false;
                        }
                    }
                };
            },
            getStatusFieldComputed() {
                return (nameField) => {
                    return this.fields[nameField].btClose;
                };
            },
            getIconFieldComputed() {
                return (nameField) => {
                    if (nameField === 'currentPassword') {
                        return changeProfileConfig.fields.currentPassword.icon.get(
                            this.isTypeFieldCurrentPassword
                        );
                    }

                    if (nameField === 'password') {
                        return changeProfileConfig.fields.password.icon.get(
                            this.isTypeFieldPassword
                        );
                    }

                    return null;
                };
            },
            getFieldTypeCurrentPasswordComputed() {
                return this.isTypeFieldCurrentPassword ? 'password' : 'text';
            },
            getFieldTypePasswordComputed() {
                return this.isTypeFieldPassword ? 'password' : 'text';
            },
            currentPasswordErrorsComputed() {
                const currentPasswordField = this.$v.user.currentPassword;

                if (!currentPasswordField.$dirty) {
                    return [];
                }

                const errors = [];

                !currentPasswordField.required &&
                    errors.push(
                        changeProfileConfig.messages.currentPassword.alerts
                            .MANDATORY_CURRENT_PASSWORD
                    );

                const minCurrentPasswordLength =
                    changeProfileConfig.fields.currentPassword.MIN_LENGTH;

                !currentPasswordField.minLength &&
                    errors.push(
                        changeProfileConfig.messages.currentPassword.alerts.MIN_LENGTH_CURRENT_PASSWORD(
                            minCurrentPasswordLength
                        )
                    );

                return errors;
            },
            currentPasswordSuccessComputed() {
                const currentPasswordField = this.$v.user.currentPassword;

                this.toggleFieldBgColor('currentPassword', currentPasswordField);

                return !currentPasswordField.$invalid;
            },
            passwordErrorsComputed() {
                const passwordField = this.$v.user.password;

                if (!passwordField.$dirty) {
                    return [];
                }

                const errors = [];

                !passwordField.required &&
                    errors.push(
                        changeProfileConfig.messages.password.alerts.MANDATORY_PASSWORD
                    );

                const minPasswordLength = changeProfileConfig.fields.password.MIN_LENGTH;

                !passwordField.minLength &&
                    errors.push(
                        changeProfileConfig.messages.password.alerts.MIN_LENGTH_PASSWORD(
                            minPasswordLength
                        )
                    );

                return errors;
            },
            passwordSuccessComputed() {
                const passwordField = this.$v.user.password;

                this.toggleFieldBgColor('password', passwordField);

                return !passwordField.$invalid;
            },
            confirmPasswordErrorsComputed() {
                const confirmPasswordField = this.$v.user.confirmPassword;

                if (!confirmPasswordField.$dirty) {
                    return [];
                }

                const errors = [];

                !confirmPasswordField.required &&
                    errors.push(
                        changeProfileConfig.messages.confirmPassword.alerts
                            .MANDATORY_CONFIRM_PASSWORD
                    );

                const minConfirmPasswordLength =
                    changeProfileConfig.fields.password.MIN_LENGTH;

                !confirmPasswordField.minLength &&
                    errors.push(
                        changeProfileConfig.messages.confirmPassword.alerts.MIN_LENGTH_CONFIRM_PASSWORD(
                            minConfirmPasswordLength
                        )
                    );

                return errors;
            },
            confirmPasswordSuccessComputed() {
                const confirmPasswordField = this.$v.user.confirmPassword;

                this.toggleFieldBgColor('confirmPassword', confirmPasswordField);

                return !confirmPasswordField.$invalid;
            }
        },
        validations() {
            const passwordMinLength = changeProfileConfig.fields.password.MIN_LENGTH;

            const validations = {
                user: {
                    currentPassword: {
                        required,
                        minLength: minLength(passwordMinLength)
                    },
                    password: {
                        required,
                        minLength: minLength(passwordMinLength)
                    },
                    confirmPassword: {
                        required,
                        minLength: minLength(passwordMinLength)
                    }
                }
            };

            return {
                user: validations.user
            };
        },
        methods: {
            populateMock() {
                this.users = Object.assign(this.user, userMock);
            },
            populateGeral() {
                if (isAmbientGeralTests && this.isAmbientLocalTests) {
                    this.populateMock();
                }
            },
            checkStateFields() {
                for (const key of Object.keys(this.fields)) {
                    if (this.user[key] && this.user[key].length) {
                        this.toggleStatusActivedField(key);
                    }
                }
            },
            toggleStatusActivedField(nameField) {
                this.fields[nameField].btClose = !this.fields[nameField].btClose;
            },
            activeField(nameField) {
                this.fieldSelected = nameField;
            },
            closeField(nameField) {
                this.user[nameField] = null;

                this.activeField(nameField);

                this.focusField(nameField);

                this.fields[nameField].btClose = false;
            },
            getLabel(nameField) {
                return changeProfileConfig.fields[nameField].LABEL;
            },
            closeSnack() {
                this.snackActivated = false;

                const fieldNameActivated = this.fieldNameActivated;

                if (fieldNameActivated) {
                    this.focusField(fieldNameActivated);
                    this.changeColorField(fieldNameActivated);
                }
            },
            toggleFieldBgColor(nameField, field) {
                this.fields[nameField].bgColor =
                    field.$dirty && field.$invalid
                        ? this.configs.fields.bgColor.error
                        : null;
            },
            changeColorField(field) {
                this.fields[field].bgColor = this.configs.fields.bgColor.error;
            },
            focusField(field) {
                this.$refs[field].focus();
            },
            validatePasswordEquals() {
                return (
                    this.user.password &&
                    this.user.confirmPassword &&
                    this.user.password === this.user.confirmPassword
                );
            },
            validForm() {
                let valid = true,
                    key = null;

                const fields = {
                    currentPassword: {
                        status: this.currentPasswordSuccessComputed,
                        message:
                            changeProfileConfig.messages.currentPassword.errors
                                .CHECK_CURRENT_PASSWORD
                    },
                    password: {
                        status: this.passwordSuccessComputed,
                        message:
                            changeProfileConfig.messages.password.errors.CHECK_PASSWORD
                    },
                    confirmPassword: {
                        status: this.confirmPasswordSuccessComputed,
                        message:
                            changeProfileConfig.messages.confirmPassword.errors
                                .CHECK_CONFIRM_PASSWORD
                    },
                    checkPasswordEquals: {
                        status: this.validatePasswordEquals(),
                        message:
                            changeProfileConfig.messages.passwordEquals.errors
                                .CHECK_PASSWORD_EQUALS
                    }
                };

                for (const k of Object.keys(fields)) {
                    if (!fields[k].status) {
                        valid = false;
                        key = k;
                        break;
                    }
                }

                if (!valid) {
                    this.fieldNameActivated =
                        key === 'checkPasswordEquals' ? 'confirmPassword' : key;

                    this.snackActivated = true;

                    this.snackMessage = fields[key].message;
                }

                return valid;
            },
            activeMessage(snackMessage, success) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = snackMessage;
            },
            filterMessageError(e) {
                const result = JSON.parse(JSON.stringify(e));

                if (result.message === 'Network Error') {
                    return {
                        field: null,
                        message: 'Erro de conexão com a API!'
                    };
                } else {
                    const { error } = e.response.data.status.payload;

                    const map = {
                        default: {
                            field: null,
                            message: 'Erro genérico!'
                        },
                        currentPasswordInvalid: {
                            field: 'currentPassword',
                            message: 'A senha atual é inválida!'
                        },
                        newPasswordInvalid: {
                            field: 'password',
                            message: 'A nova senha é inválida!'
                        },
                        passwordsIsEquals: {
                            field: 'password',
                            message: 'A nova senha não pode ser igual a senha atual!'
                        }
                    };

                    const exists = map.hasOwnProperty(error);

                    return exists ? map[error] : map['default'];
                }
            },
            submitForm() {
                if (!this.validForm()) return;

                this.loadingActivated = true;

                this.$service.http[geralConfig.service.http]
                    .changePassword(this.user)
                    .then((_) => {
                        this.submitedForm = true;
                    })
                    .catch((e) => {
                        const { field, message } = this.filterMessageError(e);

                        this.activeMessage(message, false);

                        if (field) {
                            this.fieldNameActivated = field;
                        }
                    })
                    .finally((_) => {
                        this.loadingActivated = false;
                    });
            }
        }
    };
</script>

<style scoped>
    .box-submited {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .box-submited .info-title {
        margin-top: 10px;
    }

    .field {
        padding: 0px !important;
        position: relative;
    }

    .close-field {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: -20px;
    }

    .btn-forgot-password {
        margin-top: 15px;
        text-transform: none;
        font-weight: bold;
        letter-spacing: normal;
    }

    .btn-signup {
        text-transform: none;
        font-weight: bold;
        letter-spacing: normal;
        background-color: #ffffff;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        position: relative !important;
        /* -webkit-box-shadow: 0 0 0px 0px transparent outset; */
        transition: background-color 5000s ease-in-out 0s;
    }

    .box {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
</style>
