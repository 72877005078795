var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","lg3":"","xl3":""}},[_c('SanackBar',{attrs:{"snackActivated":_vm.snackActivated,"success":_vm.snackSuccess,"snackMessage":_vm.snackMessage},on:{"closeSnackBar":function($event){return _vm.closeSnack()}}}),_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titles.toolbar))]),_c('v-spacer'),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingActivated),expression:"loadingActivated"}],attrs:{"indeterminate":"","color":"white","width":"2"}})],1),(_vm.submitedForm)?_c('v-card-text',[_c('v-container',{staticClass:"box-submited"},[_c('h1',{staticClass:"info-title"},[_vm._v("Senha alterada!")]),_c('p',{staticClass:"info-msg"},[_vm._v("Sua senha foi alterada corretamente!")])])],1):_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"field"},[_c('CloseField',{attrs:{"name":'currentPassword',"activated":_vm.getStatusFieldComputed(
                                                    'currentPassword'
                                                )},on:{"closeSpeficField":function($event){return _vm.closeField($event)}}}),_c('v-text-field',{ref:"currentPassword",attrs:{"label":_vm.getLabel('currentPassword'),"disabled":_vm.loadingActivated,"name":"currentPassword","background-color":_vm.fields.currentPassword.bgColor,"append-icon":_vm.getIconFieldComputed(
                                                    'currentPassword'
                                                ),"type":_vm.getFieldTypeCurrentPasswordComputed,"filled":"","rounded":"","dense":"","error-messages":_vm.currentPasswordErrorsComputed,"success":_vm.currentPasswordSuccessComputed},on:{"select":function($event){return _vm.activeField('currentPassword')},"focus":function($event){return _vm.activeField('currentPassword')},"click:append":function($event){_vm.isTypeFieldCurrentPassword =
                                                    !_vm.isTypeFieldCurrentPassword}},model:{value:(
                                                _vm.$v.user.currentPassword.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.user.currentPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n                                                $v.user.currentPassword.$model\n                                            "}})],1),_c('v-container',{staticClass:"field"},[_c('CloseField',{attrs:{"name":'password',"activated":_vm.getStatusFieldComputed('password')},on:{"closeSpeficField":function($event){return _vm.closeField($event)}}}),_c('v-text-field',{ref:"password",attrs:{"label":_vm.getLabel('password'),"disabled":_vm.loadingActivated,"name":"password","background-color":_vm.fields.password.bgColor,"append-icon":_vm.getIconFieldComputed('password'),"type":_vm.getFieldTypePasswordComputed,"filled":"","rounded":"","dense":"","error-messages":_vm.passwordErrorsComputed,"success":_vm.passwordSuccessComputed},on:{"select":function($event){return _vm.activeField('password')},"focus":function($event){return _vm.activeField('password')},"click:append":function($event){_vm.isTypeFieldPassword =
                                                    !_vm.isTypeFieldPassword}},model:{value:(_vm.$v.user.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.password.$model"}})],1),_c('v-container',{staticClass:"field"},[_c('CloseField',{attrs:{"name":'confirmPassword',"disabled":_vm.loadingActivated,"activated":_vm.getStatusFieldComputed(
                                                    'confirmPassword'
                                                )},on:{"closeSpeficField":function($event){return _vm.closeField($event)}}}),_c('v-text-field',{ref:"confirmPassword",attrs:{"label":_vm.getLabel('confirmPassword'),"type":"password","name":"confirmPassword","background-color":_vm.fields.confirmPassword.bgColor,"filled":"","rounded":"","dense":"","error-messages":_vm.confirmPasswordErrorsComputed,"success":_vm.confirmPasswordSuccessComputed},on:{"select":function($event){return _vm.activeField('confirmPassword')},"focus":function($event){return _vm.activeField('confirmPassword')}},model:{value:(
                                                _vm.$v.user.confirmPassword.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.user.confirmPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n                                                $v.user.confirmPassword.$model\n                                            "}})],1),_c('SubmitFormButton',{attrs:{"title":_vm.titles.submitButton,"disabled":_vm.loadingActivated},on:{"submitForm":function($event){return _vm.submitForm()}}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }