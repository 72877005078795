import geral from './geral.config';

const configs = {
    geral: {
        ...geral
    },
    titles: {
        SECTOR: 'Alterar senha'
    },
    fields: {
        currentPassword: {
            LABEL: 'Senha atual',
            MIN_LENGTH: 6,
            icon: {
                TEXT: 'visibility_off',
                PASSWORD: 'visibility',
                get(isTypeFieldCurrentPassword) {
                    return isTypeFieldCurrentPassword ? this.PASSWORD : this.TEXT;
                }
            }
        },
        password: {
            LABEL: 'Nova senha',
            MIN_LENGTH: 6,
            icon: {
                TEXT: 'visibility_off',
                PASSWORD: 'visibility',
                get(isTypeFieldPassword) {
                    return isTypeFieldPassword ? this.PASSWORD : this.TEXT;
                }
            }
        },
        confirmPassword: {
            LABEL: 'Repetir nova senha'
        }
    },
    buttons: {
        SUBMIT: 'Alterar senha'
    },
    messages: {
        currentPassword: {
            alerts: {
                MANDATORY_CURRENT_PASSWORD: 'Senha atual obrigatória!',
                MIN_LENGTH_CURRENT_PASSWORD: (minLength) => {
                    return `Insira pelo menos ${minLength} caracteres!`;
                }
            },
            errors: {
                CHECK_CURRENT_PASSWORD: 'Preencha o campo senha atual!',
                CURRENT_PASSWORD_VALID: 'Insira sua senha atual válida!'
            }
        },
        password: {
            alerts: {
                MANDATORY_PASSWORD: 'Senha obrigatória!',
                MIN_LENGTH_PASSWORD: (minLength) => {
                    return `Insira pelo menos ${minLength} caracteres!`;
                }
            },
            errors: {
                CHECK_PASSWORD: 'Preencha o campo nova senha!',
                PASSWORD_VALID: 'Insira uma senha válida!'
            }
        },
        confirmPassword: {
            alerts: {
                MANDATORY_CONFIRM_PASSWORD: 'Repetição de senha obrigatória!',
                MIN_LENGTH_CONFIRM_PASSWORD: (minLength) => {
                    return `Insira pelo menos ${minLength} caracteres!`;
                }
            },
            errors: {
                CHECK_CONFIRM_PASSWORD: 'Preencha o campo repetir nova senha!',
                CONFIRM_PASSWORD_VALID: 'Insira uma repetição de senha válida!'
            }
        },
        passwordEquals: {
            errors: {
                CHECK_PASSWORD_EQUALS: 'A nova senha e a repetição não são iguais!'
            }
        }
    }
};

export default configs;
